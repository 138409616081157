<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">API Key</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::API key-->
        <div class="example mb-10">
          <div class="example-code">
            <div class="example-code">
              <div class="example-highlight">
                {{ currentUser.token }}
              </div>
            </div>
          </div>
        </div>
        <!--end::API key-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "setting",
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
